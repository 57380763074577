// import React, { useState } from 'react';
import Style from './Navbar.module.scss';
import Toggler from "./home/Toggler";
// import { useLocation } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { Box } from "@mui/material";
import { info } from "../info/Info";
import { singlePage } from '../info/Info';

const links = [
    {
        name: 'Contact Me',
        to: 'contact',
        active: 'contact'
    },
    {
        name: 'About Me',
        to: 'about',
        active: 'about'
    },
    {
        name: info.initials,
        type: 'initials',
        to: '',
        active: ''
    },
    {
        name: 'Portfolio',
        to: 'portfolio',
        active: 'portfolio'
    }
]

// This function is used to create a scroll offset to compensate for the navbar
// when you click on the nav buttons to scroll down.
const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}


export default function Navbar({ darkMode, handleClick, active, setActive }) {

    return (
      <Box
        component={"nav"}
        width={"100%"}
        height={"20vh"}
        position={singlePage ? "fixed" : "relative"}
        className={darkMode ? Style.dark : Style.light}
      >
        <Box
          margin={"0 5px"}
          component={"ul"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={{ xs: "1rem", md: "8rem" }}
          textTransform={"lowercase"}
          fontSize={"1rem"}
          className={Style.gradient_border}
        >
          {links.map((link, index) => (
            <Box
              key={index}
              component={"li"}
              className={link.active === active && !link.type && Style.active}
              sx={{ borderImageSource: info.gradient }}
            >
              <Link
                to={singlePage ? `#${link.to}` : `/${link.to}`}
                scroll={(el) => scrollWidthOffset(el)}
                smooth
                onClick={() => setActive(link.active)}
                className={Style.link}
              >
                {!link.type && (
                  <h3 style={{ padding: "0.5rem 0", textAlign: "center" }}>
                    {link.name}
                  </h3>
                )}

                {link.type && (
                  <img alt="logo" className={Style.logo} src={link.name} />
                )}
              </Link>
            </Box>
          ))}
          <li>
            <Toggler darkMode={darkMode} handleClick={handleClick} />
          </li>
        </Box>
      </Box>
    );
}