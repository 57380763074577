import self from "../img/self.png";
import mock1 from "../img/mock1.png";
import mock2 from "../img/mock2.png";
// import mock3 from "../img/mock3.png";
import mock4 from "../img/mock5.png";
// import mock5 from "../img/mock4.png";
import logo from "../img/logo-2.png";
import check from "../img/check-mark.png";
import email from "../img/email.png";
// import blue from "../img/blue.jpg"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */

/* 
This variable will change the layout of the website from multipage to single, scrollable page
*/
export let singlePage = false;
// export const tik=check;
/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
  firstName: "Yabibal",
  lastName: "Hunie",
  initials: logo, // the example uses first and last, but feel free to use three or more if you like.
  position: "a Full Stack Developer",
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  tik: check,
  email: email,
  miniBio: [
    // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
    {
      emoji: "🌎",
      text: "based in Ethopia",
    },
    {
      emoji: "⏰",
      text: "Comfortable Across Time Zones",
    },
    {
      emoji: "📧",
      text: "yabibalhunie@gmail.com",
    },
    {
      emoji: "📱",
      text: "+251 924 904 995",
    },
  ],
  socials: [
    {
      link: "https://github.com/yabibal-Hu",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://www.linkedin.com/in/yabibal-hunie-a8200a304/",
      icon: "fa fa-linkedin",
      label: "linkedin",
    },
    // {
    //   link: "https://facebook.com",
    //   icon: "fa fa-facebook",
    //   label: "facebook",
    // },
    // {
    //   link: "https://instagram.com",
    //   icon: "fa fa-instagram",
    //   label: "instagram",
    // },

    // {
    //   link: "https://twitter.com",
    //   icon: "fa fa-twitter",
    //   label: "twitter",
    // },
    // Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
    // Just change the links so that they lead to your social profiles.
  ],
  bio: "Hello! I'm Yabibal Hunie. I'm a passionate web developer based in Ethiopia. With a strong background in both front-end and back-end development, I create functional and user-friendly websites that leave a lasting impression.",
  skills: {
    proficientWith: [
      "react",
      "nodejs",
      "express",
      "MYSQL",
      "javascript",
      "github",
      "git",
      "adobe photoshop",
      "bootstrap",
      "html5",
      "css3",
    ],
    exposedTo: ["Nextjs", "python", "Tailwind"],
  },
  hobbies: [
    {
      label: "Tech Meetups/Conferences",
      emoji: "🖥️",
    },
    {
      label: "Puzzle Games",
      emoji: "🧩",
    },
    {
      label: "walk",
      emoji: "🚶‍♂️",
    },
    {
      label: "Coding Challenges ",
      emoji: "💻",
    },
    // Same as above, change the emojis to match / relate to your hobbies or interests.
    // You can also remove the emojis if you'd like, I just think they look cute :P
  ],
  portfolio: [
    // This is where your portfolio projects will be detailed
    {
      //   title: "Project 4",
      live: "https://forum.yabibal.com/",
      source: "#",
      image: mock4,
    },
    {
      title: "Forum-APP",
      overview:
        "Evangadi Forum is a full-stack web application designed for users to ask questions and post answers, creating a collaborative environment for knowledge sharing. This application includes both a client side and a server side, along with user authentication functionality. This project was created during my time as a student at Evangadi Coding Academy, with group collaboration playing a key role in its development.",
      technology: [
        <div style={{ display: "flex", gap: "4rem" }}>
          <div>
            <p>👉 React-Vite</p>
            <p>👉 javascript</p>
            <p>👉 Express</p>
            <p>👉 mysql </p>
          </div>
          <div>
            <p>👉 bootstrap</p>
            <p>👉 Axios</p>
            <p>👉 react-type-animation</p>
            <p>👉 ...</p>
          </div>
        </div>,
      ],
      challenge: [
        <p>
          👉 <b>User Authentication: </b> Secure login and registration with
          password hashing and JWT-based authentication.
        </p>,
        <p>
          👉 <b>API Integration:</b> The backend provides robust API endpoints
          for handling user data, question management, and interactions.
        </p>,
        <p>
          👉 <b>Question and Answer Management: </b> Users can post questions,
          provide answers, and interact with other community members.
        </p>,
        <p>
          👉 <b>Responsive Design:</b> The frontend is optimized for both
          desktop and mobile devices, ensuring accessibility and usability.
        </p>,
        <p>
          👉 <b>Dynamic UI Components: </b> Utilizes modern UI frameworks and
          libraries to deliver a seamless user experience.
        </p>,
       
      ],
      source: "https://github.com/paytonjewell",
    },
    {
      //   title: "AMAZON-CLONE",
      live: `https://amazonclo.netlify.app/`,
      source: "#",
      image: mock1,
    },
    {
      title: "AMAZON-CLONE",
      overview:
        "The Amazon Clone is a full-stack e-commerce web application built as a learning project to test and enhance my skills in full-stack development. The project replicates key features of the Amazon website, including user authentication, product browsing, a dynamic shopping cart, and secure payment processing through Stripe. It serves as a hands-on experience in using modern web technologies to create a responsive and functional web application.",
      technology: [
        <div style={{ display: "flex", gap: "4rem" }}>
          <div>
            <p>👉 React-Vite</p>
            <p>👉 javascript</p>
            <p>👉 Firebase</p>
            <p>👉 Sripe</p>
          </div>
          <div>
            <p>👉 Express</p>
            <p>👉 nodejs</p>
            <p>👉 bootstrap</p>
            <p>👉 Axios</p>
          </div>
        </div>,
      ],
      challenge: [
        <p>
          👉 <b>User Authentication:</b> Firebase handles secure user login and
          registration.
        </p>,
        <p>
          👉 <b>Product Management:</b> Users can browse, view details, and
          manage their shopping cart.
        </p>,
        <p>
          👉 <b>Payment Processing:</b> Integrated Stripe for secure
          transactions.{" "}
        </p>,
        <p>
          👉 <b>Responsive Design:</b> Optimized for both desktop and mobile
          platforms.
        </p>,
      ],
    },
    {
      //   title: "Project 3",
      live: "https://clonnetflix.vercel.app/",
      source: "#",
      image: mock2,
    },
    {
      title: "NETFLIX-CLONE",
      overview:
        "The Netflix Clone is a frontend web application designed to replicate the core features of the Netflix streaming platform. Built as a learning project, this clone allows users to browse and watch movie trailers, showcasing the use of modern web development technologies and practices. The project emphasizes responsive design, API integration, and interactive UI components, making it a comprehensive exercise in frontend development.",
      technology: [
        <div style={{ display: "flex", gap: "4rem" }}>
          <div>
            <p>👉 React-Vite</p>
            <p>👉 javascript</p>
            <p>👉 Firebase</p>
            <p>👉 Themovie-DB</p>
          </div>
          <div>
            <p>👉 React YouTube & Movie-Trailer</p>
            <p>👉 bootstrap</p>
            <p>👉 Axios</p>
            <p>👉 ...</p>
          </div>
        </div>,
      ],
      challenge: [
        <p>
          👉 <b>Movie Browsing: </b> Users can browse a selection of movies,
          similar to Netflix’s interface, with dynamic categories.
        </p>,
        <p>
          👉 <b>Trailer Playback:</b> Integrated YouTube player allows users to
          watch movie trailers directly on the site.
        </p>,
        <p>
          👉 <b>Responsive Design: </b> Optimized for both desktop and mobile
          viewing, ensuring a smooth user experience across devices.
        </p>,
      ],
    },

    // {
    //   //   title: "Project 5",
    //   live: "https://paytonpierce.dev",
    //   source: "https://github.com/paytonjewell",
    //   image: mock5,
    // },
  ],
};
