import React from "react";
import PortfolioBlock from "./PortfolioBlock";
import DiscriptionBlock from "./DiscriptionBlock";
import { Box, Grid } from "@mui/material";
import { info } from "../../info/Info";
import './Portfolio.scss';
// import { BorderBottom } from "@mui/icons-material";

const projects = [
  {
    image: info.portfolio[0].image,
    live: info.portfolio[0].live,
    source: info.portfolio[0].source,
  },
  {
    title: info.portfolio[1].title,
    overview: info.portfolio[1].overview,
    Technology: info.portfolio[1].technology,
    challenge: info.portfolio[1].challenge,
  },
  {
    image: info.portfolio[2].image,
    live: info.portfolio[2].live,
    source: info.portfolio[2].source,
  },
  {
    title: info.portfolio[3].title,
    overview: info.portfolio[3].overview,
    Technology: info.portfolio[3].technology,
    challenge: info.portfolio[3].challenge,
  },
  {
    image: info.portfolio[4].image,
    live: info.portfolio[4].live,
    source: info.portfolio[4].source,
  },
  {
    title: info.portfolio[5].title,
    overview: info.portfolio[5].overview,
    Technology: info.portfolio[5].technology,
    challenge: info.portfolio[5].challenge,
  },
];
export default function Portfolio({ innerRef }) {
  return (
    <Box id={"portfolio"} ref={innerRef}>
      <Grid container display={"flex"} justifyContent={"center"}>
        {projects.map((project, index) => (
          <Grid className="gradient-border" item xs={12} md={6} key={index}>
            {/* {!project.title && <PortfolioBlock image={project.image} live={project.live} source={project.source} title={project.title} />} */}
            {!project.title ? (
              <PortfolioBlock
                image={project.image}
                live={project.live}
                source={project.source}
                title={project.title}
              />
            ) : (
              <DiscriptionBlock
                title={project.title}
                overview={project.overview}
                Technology={project.Technology}
                challenge={project.challenge}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
