import React from 'react';
// import IconLink from "./IconLink";
import {Box} from "@mui/material";

// import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSafari, faGithub} from "@fortawesome/free-brands-svg-icons";

function PortfolioBlock(props) {
   const {image, live, source, title} = props;
   console.log(live)
   return (
     <Box
       display={"flex"}
       flexDirection={"column"}
       justifyContent={"center"}
       alignItems={"center"}
     >
       <Box component={"img"} src={image} alt={"mockup"} />
       <h1 style={{ fontSize: "2rem" }}>{title}</h1>
       <Box
         className={"portfolio"}
         display={"flex"}
         flexDirection={"row"}
         gap={"0.5rem"}
         alignItems={"center"}
         fontSize={"1.5rem"}
         py={"1rem"}
       >
         {/* change font size on mobile */}
         <Box
           p={1}
           border={"none"}
           background={"linear-gradient(rgb(0,255,164), rgb(166,104,255))"}
           backgroundColor={"rgb(0,215,164)"}
           borderRadius={"25px"}
           sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}
         >
           <a href={live} target="_blank" rel="noopener noreferrer">
             <FontAwesomeIcon icon={faSafari} style={{ marginRight: "8px" }} />
             Live Demo
           </a>
         </Box>
         <Box
           p={1}
           border={"none"}
           borderRadius={"25px"}
           backgroundColor={"rgb(166,104,255)"}
           sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}
         >
           {/* <IconLink link={source} title={"Source Code"} icon={"fa fa-code"} /> */}
           <a href={source} target="_blank" rel="noopener noreferrer">
             <FontAwesomeIcon icon={faGithub} style={{ marginRight: "8px" }} />
             Source Code
           </a>
         </Box>
       </Box>
     </Box>
   );
}

export default PortfolioBlock;