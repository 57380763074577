import style from "./App.module.scss";
import BaseLayout from "./components/BaseLayout";
import { BrowserRouter } from "react-router-dom";
import AnimatedCursor from "./hooks/AnimatedCursor";
// import { ModeEditOutline } from "@mui/icons-material";
// import { useEffect,useRef } from "react";

// import './cursor.css';

function App() {
// const mode = JSON.parse(localStorage.getItem('darkMode'));
// useEffect(() => {
//   let mode = JSON.parse(localStorage.getItem("darkMode"));
//   console.log(mode);

//    }, []);


  return (
    <div >
      <BrowserRouter>
        <div className={style.cursor__dot}>
          <AnimatedCursor
            innerSize={15}
            outerSize={15}
            color="355, 265 ,355"
            outerAlpha={0.4}
            innerScale={0.7}
            outerScale={5}
          />
        </div>
        <BaseLayout />
      </BrowserRouter>
    </div>
  );
}

export default App;
