import React, { useState } from "react";
import Style from "./Contact.module.scss";
import { Box } from "@mui/material";
import { BarLoader } from "react-spinners";
import Button from "react-bootstrap/Button";
// import React from "react";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-routor-dom"
// import Modal from "react-bootstrap/Modal";
// import Terminal from "./Terminal";
import { info } from "../../info/Info";
function Contact() {
  // const navigate = useNavigate();
  const [loading, setLoading] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    // setTimeout(() => {
      setShow(false);
navigate("/");
      // navigate("/");
    // }, 2000);
  };
  //  const handleShow = () => setShow(true);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading("try");
    // setShow(false);
    // setShowOverlay(true);
    // setTimeout(() => {
    //   setShow(false);
    // }, 2000);
    const formData = new FormData(event.target);

    formData.append("access_key", "1d2caecf-a190-4ba2-aca8-039db003f26a");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      // console.log("Success", res);
      setLoading("yes");
      // setShow(true);
      // setTimeout(() => {
        setShow(true);
      // }, 4000);
    }
  };

  return (
    <>
      <Box
        className={`${Style.contact__container} ${
          loading === "yes" && Style.contact__container__submitted
        }`}
      >
        <form onSubmit={onSubmit} action="" className={Style.contact__left}>
          <Box className={Style.contact__left__title}>
            <h2>Contact us</h2>
            <hr />
          </Box>
          <input
            required
            type="text"
            placeholder="Your Name"
            name="name"
            className={Style.contact__input}
          ></input>
          <input
            required
            type="email"
            placeholder="Your Email"
            name="email"
            className={Style.contact__input}
          ></input>
          <textarea
            required
            placeholder="Your Message"
            name="message"
            className={Style.contact__input}
          ></textarea>
          <button variant="primary" type="submit" className="contact__btn">
            {!loading ? (
              <>
                Send
                <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
              </>
            ) : loading === "try" ? (
              <BarLoader color="#fdb30d" />
            ) : (
              <p style={{ color: "orange" }}>Submitted</p>
            )}
          </button>
        </form>
        <Box
          sx={{
            display: { xs: "none", sm: "block" }, // Hide on mobile (xs), show on larger screens (sm and up)
          }}
          className={Style.contact__right}
        >
          <img src={info.email} alt="" className={Style.contact__icon} />
        </Box>

        {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
          </Button> */}
        <div
          className={Style.container__submitted}
          // width={xs: "60%"}
          style={{
            // width: "70vw",
            visibility: show ? "visible" : "hidden",
            top: show ? "50%" : "0",
            transform: show
              ? "translate(-50%, -50%) scale(1)"
              : "translate(-50%, -50%) scale(0)",
          }}
        >
          <img src={info.tik} alt="" />
          <h2>Thank you</h2>
          <p>Your message has been successfully submitted</p>
          <Button onClick={handleClose}>Ok</Button>
        </div>
      </Box>
    </>
  );
}

export default Contact;
