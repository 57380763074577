import React from 'react';
import {Box} from "@mui/material";

export default function Toggler({darkMode, handleClick}) {
    const transition = 'all 250ms ease'

   return (
     <Box
       fontSize={"1.5rem"}
       sx={{
         cursor: "pointer",
         ":hover": { transform: "translateY(-3px)", transition: transition },
       }}
     >
       {darkMode ? (
         <span
           style={{ textShadow: "1px 1px 26px white" }}
           onClick={handleClick}
           aria-label="Full Moon"
           role="img"
         >
           🌞
         </span>
       ) : (
         <span
           style={{ textShadow: "1px 1px 26px black" }}
           onClick={handleClick}
           aria-label="New Moon"
           role="img"
         >
           🌚
         </span>
       )}
     </Box>
   );
}