import React from "react";
import { useState } from "react";
import clipboardCopy from "clipboard-copy"; //
import Style from "./Home.module.scss";
import me from "../../img/self.png";
import classNames from "classnames";
import EmojiBullet from "./EmojiBullet";
import SocialIcon from "./SocialIcon";
import { Box } from "@mui/material";
import { info } from "../../info/Info";

export default function Home({ innerRef }) {
  const [copied, setCopied] = useState(false);
  const email = 'yabibalhunie@gmail.com'; // Replace with your email

  const handleCopy = () => {
    clipboardCopy(email)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset icon after 2 seconds
      })
      .catch((err) => {
        console.error('Could not copy email: ', err);
      });
  };
  return (
    <Box
      ref={innerRef}
      component={"main"}
      display={"flex"}
      flexDirection={{ xs: "column", md: "row" }}
      alignItems={"center"}
      justifyContent={"center"}
      minHeight={"calc(100vh - 175px)"}
      id={"home"}
    >
      <Box
        className={classNames(Style.avatar, Style.shadowed)}
        alt={"image of developer"}
        style={{ background: info.gradient }}
        component={"img"}
        src={me}
        width={{ xs: "35vh", md: "40vh" }}
        height={{ xs: "35vh", md: "40vh" }}
        borderRadius={"50%"}
        p={"0.75rem"}
        mb={{ xs: "1rem", sm: 0 }}
        mr={{ xs: 0, md: "2rem" }}
      />
      <Box>
        <h1>
          Hi, I'm{" "}
          <span
            style={{
              background: info.gradient,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {info.firstName}
          </span>
          <span className={Style.hand}>🤚</span>
        </h1>
        <h2>I'm {info.position}.</h2>
        <Box component={"ul"} p={"0.8rem"}>
          {info.miniBio.map((bio, index) => (
            <Box className={Style.email_container}>
              <EmojiBullet key={index} emoji={bio.emoji} text={bio.text} />
              {index === info.miniBio.length - 2 && (
                <button onClick={handleCopy} aria-label="Copy email">
                  <i
                    className={`fa ${copied ? "fa-check" : "fa-copy"}`}
                    aria-hidden="true"
                    style={
                      copied
                        ? { color: info.baseColor }
                        : { color: "rgb(166,104,275)" }
                    }
                  >
                    <small
                      style={{
                        marginLeft: "0.2rem",
                        fontSize: "1rem",
                        opacity: "0.5",
                      }}
                      yabibalhunie
                    >
                      <sup>{copied ? "Copied!" : "Copy here"}</sup>
                    </small>
                  </i>
                </button>
              )}
            </Box>
          ))}
        </Box>
        <Box
          display={"flex"}
          gap={"1.5rem"}
          ml={"3rem"}
          fontSize={{ xs: "2rem", md: "2.5rem" }}
        >
          {info.socials.map((social, index) => (
            <SocialIcon
              key={index}
              link={social.link}
              icon={social.icon}
              label={social.label}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
