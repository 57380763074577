import React from "react";
// import IconLink from "./IconLink";
import { Box } from "@mui/material";
// import { info } from "../../info/Info";
// import { FaHandPointer } from "react-icons/fa";

function DiscriptionBlock(props) {
  const { title, overview, Technology, challenge } = props;
  return (
    <Box
      width={"90%"}
      display={"flex"}
      gap={"0.5rem"}
      flexDirection={"column"}
      justifyContent={"center"}
      fontStyle={"gothic"}
      //////
    >
      {/* <br /> */}
      <Box
        p={1}
        marginX={"auto"}
        borderBottom={"1px solid"}
        borderRadius={"25px"}
      >
        <h3 style={{ margin: "0 auto", fontSize: "1.5rem" }}>{title}</h3>
      </Box>

      <Box>
        <h3 style={{ marginBottom: "0.5rem", fontSize: "1rem" }}>Overview</h3>
        <p
          style={{
            marginLeft: "1.5rem",
            lineHeight: "1.5rem",
            fontSize: "1rem",
          }}
        >
          {overview}
        </p>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <h3 style={{ marginBottom: "0.5rem", fontSize: "1rem" }}>
          Technologies Used
        </h3>
        <h4
          style={{
            alignItems: "center",
            marginLeft: "1.5rem",
            lineHeight: "1.5rem",

            fontSize: "0.8rem",
          }}
        >
          {Technology}
        </h4>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <h3 style={{ marginBottom: "0.5rem", fontSize: "1rem" }}>
          Key Features
        </h3>
        <p
          style={{
            marginLeft: "1.5rem",
            lineHeight: "1.5rem",
            fontSize: "1rem",
          }}
        >
          {" "}
          {challenge}
        </p>
      </Box>

      {/* <Box className={'portfolio'} display={'flex'} flexDirection={'column'} gap={'0.5rem'}
              alignItems={'center'} fontSize={'1.5rem'} py={'2rem'}>
            <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
               <IconLink link={live} title={'Live Demo'} icon={'fa fa-safari'}/>
            </Box>
            <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
               <IconLink link={source} title={'Source Code'} icon={'fa fa-code'}/>
            </Box>
         </Box> */}
    </Box>
  );
}

export default DiscriptionBlock;
